var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", { attrs: { "head-title": "检测任务详情" } }),
      _c(
        "div",
        { staticStyle: { background: "#fff", padding: "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "aa",
              attrs: {
                model: _vm.addform,
                disabled: _vm.disabled,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务名称", prop: "taskName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        model: {
                          value: _vm.addform.taskName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "taskName", $$v)
                          },
                          expression: "addform.taskName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(_vm._s(_vm.$t("AiSuanfa.detail.placeholder"))),
              ]),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据主题", prop: "sourceId2" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              "show-all-levels": false,
                              options: _vm.sourceList,
                              props: {
                                value: "id",
                                label: "name",
                                children: "children",
                                expandTrigger: "hover",
                              },
                            },
                            on: { change: _vm.sourceChange },
                            model: {
                              value: _vm.addform.sourceId2,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "sourceId2", $$v)
                              },
                              expression: "addform.sourceId2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据对象", prop: "dataObjectId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "180px" },
                              on: { change: _vm.sourceChange2 },
                              model: {
                                value: _vm.addform.dataObjectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "dataObjectId", $$v)
                                },
                                expression: "addform.dataObjectId",
                              },
                            },
                            _vm._l(_vm.sourceList2, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.code, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "限制条数", prop: "maxLimit" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              controls: false,
                              min: 0,
                              label: "限制条数",
                            },
                            model: {
                              value: _vm.addform.maxLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "maxLimit", _vm._n($$v))
                              },
                              expression: "addform.maxLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.addform.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "remark", $$v)
                              },
                              expression: "addform.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v("执行结果"),
              ]),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "执行结果" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.addform.taskResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "taskResult", $$v)
                              },
                              expression: "addform.taskResult",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "触发类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "180px" },
                              model: {
                                value: _vm.addform.triggerType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "triggerType", $$v)
                                },
                                expression: "addform.triggerType",
                              },
                            },
                            _vm._l(_vm.triggerTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "判定方法名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.addform.judgeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "judgeName", $$v)
                              },
                              expression: "addform.judgeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$route.query.algorithm_id != 8 &&
                        _vm.$route.query.algorithm_id != 41,
                      expression:
                        "\n          $route.query.algorithm_id != 8 && $route.query.algorithm_id != 41\n        ",
                    },
                  ],
                  attrs: { span: 12 },
                },
                [
                  _c("p", [_vm._v("对比字段")]),
                  _c(
                    "el-table",
                    {
                      ref: "y1table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        data: _vm.columnList,
                        "header-cell-class-name": _vm.leftheaderStyle,
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangey1,
                        select: _vm.selecty1,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldComment",
                          label: _vm.$t("AiSuanfa.detail.zh"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("AiSuanfa.detail.field"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("AiSuanfa.detail.dataType"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("p", [_vm._v("排序字段")]),
                  _c(
                    "el-table",
                    {
                      ref: "x1table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        data: _vm.columnList,
                        "header-cell-class-name": _vm.leftheaderStyle,
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                        select: _vm.selectx1,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldComment",
                          label: _vm.$t("AiSuanfa.detail.zh"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("AiSuanfa.detail.field"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("AiSuanfa.detail.dataType"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "dataType", label: "排序" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "tableSclect",
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: scope.row.sortRule,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "sortRule", $$v)
                                      },
                                      expression: "scope.row.sortRule",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "升序", value: "0" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "降序", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }