<template>
  <div>
    <head-layout head-title="检测任务详情"> </head-layout>
    <div style="background: #fff; padding: 20px">
      <el-form
        :model="addform"
        :disabled="disabled"
        label-position="right"
        label-width="100px"
        class="aa"
        ref="ruleForm"
      >
        <el-row>
          <el-form-item label="任务名称" prop="taskName">
            <el-input style="width:180px" v-model="addform.taskName"></el-input>
          </el-form-item>
        </el-row>
        <div style="width: 100%; height: 20px"></div>
        <el-divider content-position="left">{{
          $t("AiSuanfa.detail.placeholder")
        }}</el-divider>
        <div style="width: 100%; height: 20px"></div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="数据主题" prop="sourceId2">
              <el-cascader
              style="width:180px"
                :show-all-levels="false"
                v-model="addform.sourceId2"
                :options="sourceList"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  expandTrigger: 'hover',
                }"
                @change="sourceChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数据对象" prop="dataObjectId">
              <el-select style="width:180px" v-model="addform.dataObjectId" @change="sourceChange2">
                <el-option
                  v-for="(item, index) in sourceList2"
                  :key="index"
                  :label="item.code"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="限制条数" prop="maxLimit">
              <el-input-number
              style="width:180px"
                :controls="false"
                v-model.number="addform.maxLimit"
                :min="0"
                label="限制条数"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备注">
              <el-input style="width:180px" placeholder="请输入内容" v-model="addform.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="width: 100%; height: 20px"></div>
        <el-divider content-position="left">执行结果</el-divider>
        <div style="width: 100%; height: 20px"></div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="执行结果">
              <el-input style="width:180px" placeholder="请输入内容" v-model="addform.taskResult">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="触发类型">
              <el-select style="width:180px" v-model="addform.triggerType">
                <el-option
                  v-for="(item, index) in triggerTypeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="判定方法名称">
              <el-input style="width:180px" placeholder="请输入内容" v-model="addform.judgeName">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row>
        <el-col
          :span="12"
          v-show="
            $route.query.algorithm_id != 8 && $route.query.algorithm_id != 41
          "
        >
          <p>对比字段</p>
          <el-table
            border
            @selection-change="handleSelectionChangey1"
            :data="columnList"
            style="width: 100%"
            ref="y1table"
            @select="selecty1"
            :header-cell-class-name="leftheaderStyle"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="fieldComment"
              :label="$t('AiSuanfa.detail.zh')"
            >
            </el-table-column>
            <el-table-column
              prop="fieldName"
              :label="$t('AiSuanfa.detail.field')"
            >
            </el-table-column>
            <el-table-column
              prop="dataType"
              :label="$t('AiSuanfa.detail.dataType')"
            >
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <p>排序字段</p>
          <el-table
            border
            @selection-change="handleSelectionChange"
            :data="columnList"
            style="width: 100%"
            ref="x1table"
            @select="selectx1"
            :header-cell-class-name="leftheaderStyle"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="fieldComment"
              :label="$t('AiSuanfa.detail.zh')"
            >
            </el-table-column>
            <el-table-column
              prop="fieldName"
              :label="$t('AiSuanfa.detail.field')"
            >
            </el-table-column>
            <el-table-column
              prop="dataType"
              :label="$t('AiSuanfa.detail.dataType')"
            >
            </el-table-column>
            <el-table-column prop="dataType" label="排序">
              <template slot-scope="scope">
                <el-select
                  class="tableSclect"
                  v-model="scope.row.sortRule"
                  placeholder="请选择"
                >
                  <el-option label="升序" value="0"> </el-option>
                  <el-option label="降序" value="1"> </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getDataTree,
  ObjectList,
  ObjectList2,
  getDataOBJXQ2,
  SaveTask,
  TaskDetail,
  SeeTaskRecordDetail,
} from "@/api/dataAcquisition/index";

export default {
  name: "zljcDetail",
  data() {
    return {
      triggerTypeList: [
        {
          label: "手动",
          value: 1,
        },
        {
          label: "判定方法",
          value: 2,
        },
      ],

      addform: {
        taskResult: "", //执行结果
        triggerType: "", //触发类型
        judgeName: "", //判定方法名称
        templateId: "",
        taskName: "", //任务名称
        subjectPid: null, //主题域
        subjectId: null, //主题
        sourceAllId: [],
        dataObjectId: "", //数据对象id
        dataObjectName: "", //数据对象名称
        maxLimit: undefined,
        taskContent: {
          //任务内容
          //对比字段
          compareFields: [],
          sortFields: [],
          maxLimit: undefined, //限制条数
        },
        remark: "", //备注
      },

      tableData: [],
      algorithmList: [],
      sourceList: [],
      sourceList2: [],
      biaoList: [],
      columnList: [],
      multipleSelection: [],
      disabled: false,
    };
  },
  created() {
    this.MgetDataTree();

    if (this.$route.query.type == "see") {
      SeeTaskRecordDetail({ id: this.$route.query.id }).then((res) => {
        this.addform = res.data.data;
        //处理数据主题
        this.addform.sourceId2 = [
          res.data.data.subjectPid,
          res.data.data.subjectId,
        ];
        //回显数据对象
        this.sourceChange(this.addform.sourceId2);
        this.addform.maxLimit = res.data.data.taskContent.maxLimit;
        //回显字段
        // this.sourceChange2(res.data.data.dataObjectId)

        getDataOBJXQ2({
          id: res.data.data.dataObjectId,
        }).then((res) => {
          this.columnList = res.data.data.saveFields;
          this.columnList.forEach((element) => {
            this.$set(element, "sortRule", "");
          });
          //  this.$set(item, "BCinput", "")
          console.log(this.columnList, this.$refs.y1table.toggleRowSelection);
          setTimeout(() => {
            this.addform.taskContent.compareFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (element.fieldId == element2.fieldId) {
                  console.log(element.fieldId, element2.fieldId);
                  this.$refs.y1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  console.log(this.columnList[index]);
                }
              });
            });

            //排序字段
            this.addform.taskContent.sortFields.forEach((element, index) => {
              this.columnList.forEach((element2, index2) => {
                if (element.fieldId == element2.fieldId) {
                  this.$refs.x1table.toggleRowSelection(
                    this.columnList[index2],
                    true
                  );
                  element2.sortRule = element.sortRule.toString();
                }
              });
            });
          }, 1);
        });

        this.disabled = true;
      });
    }
  },

  methods: {
    leftheaderStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "seltAllbtnDis";
      }
    },

    MgetDataTree() {
      getDataTree({ object: 0 }).then((res) => {
        if (res.status == 200) {
          this.sourceList = res.data.data;
          this.sourceList.forEach((element) => {
            element.children.forEach((element2) => {
              if (element2.children) {
                element2.children = undefined;
              }
            });
          });
        }
      });
    },

    //todo 数据源下拉
    sourceListFn() {
      ObjectList2({}).then((res) => {
        this.sourceList = res.data.data;
      });
    },

    sourceChange(value) {
      let parms = {
        modelId: value[1],
        query: { current: 1, size: 100 },
      };

      ObjectList(parms).then((res) => {
        this.sourceList2 = res.data.data.records;
      });
    },
    sourceChange2(value) {
      this.sourceList2.forEach((element) => {
        if (element.id == value) {
          this.addform.dataObjectName = element.code;
        }
      });
      getDataOBJXQ2({
        id: value,
      }).then((res) => {
        this.columnList = res.data.data.saveFields;
      });
    },

    //单选，后期支持多选放开
    selectx1(selection, row) {
      // // 清除 所有勾选项
      this.$refs.x1table.clearSelection();
      // // 当表格数据都没有被勾选的时候 就返回
      // // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.x1table.toggleRowSelection(row, true);
    },

    selecty1(selection, row) {
      // // 清除 所有勾选项
      this.$refs.y1table.clearSelection();
      // // 当表格数据都没有被勾选的时候 就返回
      // // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.y1table.toggleRowSelection(row, true);
    },

    cancelFn() {
      this.$router.go(-1);
    },
    //新增保存任务
    saveFn() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$refs.y1table.selection.length < 1) {
            this.$message({
              message: "请选择对比字段",
              type: "warning",
            });
          }
          if (this.$refs.x1table.selection.length < 1) {
            this.$message({
              message: "请选择排序字段和排序",
              type: "warning",
            });
          } else {
            let addParms = JSON.parse(JSON.stringify(this.addform));
            addParms.subjectPid = this.addform.sourceId2[0];
            addParms.subjectId = this.addform.sourceId2[1];
            addParms.taskContent.maxLimit = this.addform.maxLimit;
            this.$refs.y1table.selection.forEach((element) => {
              addParms.taskContent.compareFields.push({
                fieldId: element.fieldId,
              });
            });

            this.$refs.x1table.selection.forEach((element) => {
              addParms.taskContent.sortFields.push({
                fieldId: element.fieldId,
                sortRule: element.sortRule,
              });
            });

            addParms.sourceId2 = undefined;
            addParms.sourceAllId = undefined;
            addParms.maxLimit = undefined;

            SaveTask(addParms).then((res) => {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>

.el-form-item {
  display: flex;
}

.form-item {
  flex: 1;
}

::v-deep .tableSclect .el-input--small .el-input__inner {
  height: 22px;
  line-height: 22px;
}
::v-deep .tableSclect .el-input__suffix {
  top: 3px !important;
}
::v-deep .seltAllbtnDis .cell {
  visibility: hidden;
}
.aa ::v-deep.el-form-item__content {
  margin-left: 0px !important;
}
</style>
